<template>
<div v-if="actIndex !== null && images">





	<div class="lightBox" @click.exact="closeLightBox()">
		<div class="lbMain">
			<div class="lbFoot">{{ images[actIndex].title }}</div>
			<div class="lbCont" ref="lbCont">
				<div class="image">
					<img v-bind:src="images[actIndex].src" alt="" ref="actImg" class="fadeIn">
				</div>

			</div>
			<!-- <div class="lbHead">{{ images[actIndex].alt }}</div> -->
		</div>


		<div class="btns" v-if="this.images.length > 1">
			<div class="prev" @click.stop="showPrev"><svg class="mobilBtn"><use xlink:href="/sys_img/icons.svg#prevImg"/></svg></div>
			<div class="next" @click.stop="showNext"><svg class="mobilBtn"><use xlink:href="/sys_img/icons.svg#nextImg"/></svg></div>
		</div>
	</div> 


</div>
</template>

<script>
	// import  dialogComp from "@/components/DialogComp.vue";
	// import axios from 'axios'
	// import * as tools from '@/tools.js'; 

	export default {
		name: 'LightBox',
		components: {
			// dialogComp,
		},
		props: [
		'images',
		'actIndex',
		'animation',
		],
		updated(){

			// this.preLoadImgs();
		},
		mounted(){
			// this.preLoadImgs();


		}, // end mounted 
		data: 
		function(){
			return {
				// images: ['/img/01.jpg', '/img/02.png', '/img/03.jpg', '/img/04.jpg' ],
				// images: [],
				// actIndex:  1,
				nextIndex: null,
				prevIndex: null,
			}
		}, // end data
		methods: {
			preLoadImgs(){

				if (this.nextIndex && this.images.length > 0) {
				this.calcNextIndex();
				var nImg = new Image();


				nImg.src = this.images[this.nextIndex].src;
				}

				if (this.prevIndex && this.images.length > 0) {
				this.calcPrevIndex();
				var pImg = new Image();
				pImg.src = this.images[this.prevIndex].src;
				}


				
				



			},

			closeLightBox(){

				let img = this.$refs.actImg;
				img.classList.remove("fadeIn");
				img.classList.add("fadeOut"); 

				// if(this.animation){
					setTimeout(()=>{

						this.$parent.actIndex = null;
						this.$parent.images = [];
					},600);
				// }
				

			},
        showNext() {

        	this.preLoadImgs();

        	let img = this.$refs.actImg;

        	if(this.animation){
        	img.classList.remove("fadeIn");
        	img.classList.add("fadeOut"); 
        	}

            this.calcNextIndex();
            this.calcPrevIndex();

            const self = this;
     
        	if(this.animation){
            setTimeout(function(){
            	self.$parent.actIndex = self.nextIndex;
            	img.classList.remove("fadeOut");
            	img.classList.add("fadeIn");
            },700,img);
        	} else {

            	self.$parent.actIndex = self.nextIndex;
        	}
        			
        },
         showPrev() {
        	this.preLoadImgs();
         	let img = this.$refs.actImg;

         	if(this.animation){
         		img.classList.remove("fadeIn");
         		img.classList.add("fadeOut"); 
         	}
         	this.calcNextIndex();
         	this.calcPrevIndex();

         	const self = this;

         	if(this.animation){
         		setTimeout(function(){
         	self.$parent.actIndex = self.prevIndex;
         			img.classList.remove("fadeOut");
         			img.classList.add("fadeIn");
         		},700,img);
         	} else {
         	self.$parent.actIndex = self.prevIndex;
         		
         	}
         },

        calcNextIndex() {

        		if ( (this.actIndex + 1 ) === this.images.length) {
        			this.nextIndex = 0;
        		} else {
        			this.nextIndex =  this.actIndex +1;
        		}
        },       
         calcPrevIndex() {

        		if ( this.actIndex  === 0) {
        			this.prevIndex = this.images.length - 1;
        		} else {
        			this.prevIndex =  this.actIndex - 1;
        		}
        },

		} // end methods
	} // end export default

</script>



<style scoped lang="scss">


.lightBox {
	position: fixed;
	width: 100vw;
	height: 100vh;
	// background-color:rgb(37, 34, 29);
	background-image: linear-gradient(to top, #263127, #223324);
	top: 0;
	top:0;
	left:0;
	z-index: 100;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	& .btns {
		color: white;
		width: 100%;
		position: fixed;
		display: flex;
		justify-content: space-between;

		& svg {
			width: 100%;
			height: 100%;
			stroke: #ddd;
			&:hover {
				stroke: white;
			}
		}

		& div {
			width: 2.5rem;
			height: 2.5rem;
			cursor: pointer;
				padding: 1rem;

		}
	}

	& .lbMain {
		width: 100%;
		height: 100%;

		display: flex;
		flex-direction: column;
		justify-content: center;
		color: #bdbbbb;
		text-align: center;
		font-family: f2;
		font-size: 1rem;
		font-weight: bold;
		letter-spacing: 0.7px;
		text-shadow: 1px 1px 1px black;

		& .lbHead {
			// border-bottom: 1px solid white;
		}

		& .lbCont {


		}

		& .lbFoot {
			// border-top: 1px solid white;
		}



	} // lbMain end
	
	img {
		width: auto;
		height: auto;
		max-width: 90%;
		max-height: calc(100vh - 90px);
		border-radius: 1rem;
box-shadow: 3px 3px 4px #1c1c1c;
		margin: 1rem;

		   
		animation: fadeIn 2s;
		   

	}
	img.fadeOut {
		// opacity: 0;
		// transition: opacity 1s ease-in-out;
			animation: fadeOut .7s;
	}
	img.fadeIn {
			animation: fadeIn 1.4s;

		// opacity: 1;
		// transition: opacity 1s ease-in-out;
	}

	@keyframes fadeIn {
		0% {opacity:0;}
		100% {opacity:1;}
	}
	@keyframes fadeOut {
		0% {opacity:1;}
		100% {opacity:0;}
	}
}

</style>
