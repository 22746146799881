<template>
	<div id="app">
		<div id="header">

			<!-- Backend Nav -->
			<div v-if="isAdmin()" id="beHeader" title="Backend Button" @click="toggleBeLinks">
				<div class="btn">
					<svg class="beBtn"><use xlink:href="/sys_img/icons.svg#be"/></svg>
				</div>
				<div class="cont" v-show="showBeLinks">
					<ul>
						<li @click="logOut">logOut</li>
						<li><router-link to="/pflanzenkatalogList" class="link" exact-active-class="linkActive" >Pflanzenkatalog Editor</router-link></li>
						<li><router-link to="/imgEditList" class="link" exact-active-class="linkActive" >Bild Editor</router-link></li>
						<li><router-link to="/homeEditList" class="link" exact-active-class="linkActive" >Home Editor</router-link></li>
						<li><router-link to="/schaugartenList" class="link" exact-active-class="linkActive" >Schaugarten Editor</router-link></li>
						<li><router-link to="/unsereArbeitenList" class="link" exact-active-class="linkActive" >Unsere Arbeiten Editor</router-link></li>
						<li><router-link to="/infos" class="link" exact-active-class="linkActive" >Infos</router-link></li>
					</ul>
				</div>
			</div>

			<!-- Frontend Nav -->
			<div id="feHeader">
				<div class="btn" @click="openMobileMenu">
					<svg class="mobilBtn"><use xlink:href="/sys_img/icons.svg#mobilBtn"/></svg>
				</div>

				<div class="cont">
					<div class="logo"><router-link to="/" class="link" exact-active-class="linkActive" ><img src="/sys_img/Logo.png" alt="Viridarium Logo"></router-link></div>

					<div class="innerCont">
					
						<div class="headline"><span>Viridarium</span><span>Gartengestaltung</span></div>

						<div class="links" ref="feLinks">

							<ul class="nav" @click="closeMobileMenu" >

								<li><router-link to="/" class="link" exact-active-class="linkActive" >Home</router-link></li>

								<li style="position: relative;"  @mouseover="showSubMenu('subMenGgest', true)" @click="showSubMenu('subMenGgest', false)"  @mouseleave="showSubMenu('subMenGgest', false)">
									<div><router-link to="/gartengestaltung/main" class="link" :class="[isLinkAcive('gartengestaltung') ? 'linkActive' : '']" >Gartengestaltung</router-link>
									</div>

									<div class="subNav">
						
											<ul ref="subMenGgest" @mouseleave="showSubMenu('subMenGgest', false)">
												<li @click="showSubMenu('subMenGgest', false)"><router-link to="/gartengestaltung/beratung" class="link" exact-active-class="linkActive" >Beratung und Planung</router-link></li>
												<li @click="showSubMenu('subMenGgest', false)"><router-link to="/gartengestaltung/mauern-wege" class="link" exact-active-class="linkActive" >Mauern und Wege</router-link></li>
												<li @click="showSubMenu('subMenGgest', false)"><router-link to="/gartengestaltung/teiche" class="link" exact-active-class="linkActive" >Teiche, Bachläufe, Brunnen</router-link></li>
												<li @click="showSubMenu('subMenGgest', false)"><router-link to="/gartengestaltung/bepflanzung" class="link" exact-active-class="linkActive" >Bepflanzung  und Rasenanlage </router-link></li>													<li @click="showSubMenu('subMenGgest', false)"><router-link to="/gartengestaltung/teichpflege" class="link" exact-active-class="linkActive" >Garten und Teichpflege</router-link></li>	
											</ul>

									</div>

								</li>

								<li><router-link to="/schaugarten" class="link" exact-active-class="linkActive" >Schaugarten</router-link></li>
								<li><router-link to="/unsere-arbeiten" class="link" exact-active-class="linkActive" >Unsere Arbeiten </router-link></li>
								<li><router-link to="/pflanzenkatalog" class="link" exact-active-class="linkActive" >Pflanzenkatalog</router-link></li>
								<li><router-link to="/impressum" class="link" exact-active-class="linkActive" >Impressum</router-link></li>

							</ul>
						</div>


					</div>
				</div>

			</div>
		</div>
    
		<div id="mainCont">
			<!-- render content -->
			<div id="wrapper"><router-view/></div>
		</div>

		<div id="footer">
			<div class="cr">&copy; Viridarium 2021</div class="cr">
			<ul>
				<li><router-link to="/kontakt" class="link" exact-active-class="linkActive" >Kontakt</router-link></li>
				<li><router-link to="/links" class="link" exact-active-class="linkActive" >Links</router-link></li>
				<li><router-link to="/agb" class="link" exact-active-class="linkActive" >AGB</router-link></li>
				<li><router-link to="/datenschutz" class="link" exact-active-class="linkActive" >Datenschutzerklärung</router-link></li>
				<li><router-link to="/informationspflicht" class="link" exact-active-class="linkActive" >Informationspflicht</router-link></li>
			</ul>
			
			
			
			
		</div>

  </div> <!-- app end -->


</template>

<script>
	// import  dialogComp from "@/components/DialogComp.vue";
	// import axios from 'axios'
	import * as tools from './tools.js'; 

	export default {
		name: 'App',
		components: {
			// dialogComp,
		},
		mounted(){

		}, // end mounted 
		data: 
		function(){
			return {
				showBeLinks: false
			}
		}, // end data
		methods: {

			openMobileMenu(){
				this.$refs.feLinks.style.display ='block';
			},
			closeMobileMenu(){

				let bp = getComputedStyle(document.documentElement).getPropertyValue('--bp1'); 
				bp = bp.replace('px','');
				if (document.body.clientWidth < bp) {
					this.$refs.feLinks.style.display ='none';
				}

			},
			toggleBeLinks(){
				this.showBeLinks = this.showBeLinks ? false : true;
			},
			isLinkAcive(p){

		if (this.$route.path.includes(p)) {
			return true;
		}

				return false;
			},
			showSubMenu(tag, status){


				var style = getComputedStyle(document.body);
				let bp = style.getPropertyValue('--bp2');

				bp = bp.replace(/px/, '');

				

				if (window.innerWidth > bp ) {
					
					if (status) {
						this.$refs[tag].style.display = 'block';
					} else {

						this.$refs[tag].style.display = 'none';
					}
				} else {
						this.$refs[tag].style.display = 'block';

				}




			},
			logOut(){
				document.cookie = '-viridarium=a12342ddfll; max-age=0;'
				this.isAdmin();
				this.$router.go('/');

			},
			isAdmin(){
				return tools.isAdmin();
			}


		} // end methods
	} // end export default

</script>

<style lang="scss">
@import '/tinymce/css/basic.css';

  *{
  	
  }

  $maxWrapperWidth: 860px;
  $headerOffset: 6.4rem;
  $footerOffset: 4rem;
  $bp1: 800px;
  $bp2: 600px;

:root {
  --c11: #3c6240;
  --c12: #679d6d;
  --c13: #28402a;
  --c14: #afccb3;
  --c15: #e2f2e4;
  --c21: #b9c43a;
  --c22: #d3d7ac;
  --c31: #d2c4a4;

  --bp1: #{$bp1};
  --bp2: #{$bp2};

}


@font-face {
	font-family: "f1";
	src: url("/fonts/OpenSans-Regular.ttf");
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: "f1";
	src: url("/fonts/OpenSans-Bold.ttf");
	font-weight: bold;
}

@font-face {
	font-family: "f2";
	src: url("/fonts/Comfortaa-Light.ttf");
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: "f2";
	src: url("/fonts/Comfortaa-Bold.ttf");
	font-weight: bold;
	font-style: bold;
}

  #app {
  	font-family: f1, Helvetica, Arial, sans-serif;
  	-webkit-font-smoothing: antialiased;
  	-moz-osx-font-smoothing: grayscale;
  	text-align: justify;
  	word-spacing: 2px;
  	// height: 100%;
  	color: #2c3e50;


  	display: flex;
  	flex-direction: column;
  	justify-content: stretch;
  	box-sizing: border-box;
  	line-height: 1.5rem;
  	height: 100%;


  	& > div {
  		// border: 1px solid silver;
  		
  	}

  	& > div:nth-child(1){
  		min-height: $headerOffset;
  		background-color: var(--c11);
  		
  	}
  	& > div:nth-child(2){
  		height: calc(100% - #{$headerOffset} - #{$footerOffset});
  		overflow: auto;

  		// background-color: #EE0472;
  	}
  	& > div:nth-child(3){
  		height: $footerOffset;

  		border-top: 1px solid white;
  		text-align: center;
  		background-color: var(--c11);
  		color: white;
  		// padding: .5rem;
  		font-size: .9rem;

  	}
  }

#wrapper {
	height: 100%;
	box-sizing: border-box;
	max-width: $maxWrapperWidth;
	margin: 0 auto;
	// background-color: #EEE472;
		// border: 1px solid silver;
		
	padding: 1rem;
	// overflow: auto;
		
	
}

#beHeader {
	position: fixed;
	display: flex;
	z-index: 50;
	top: 0rem;
	left: 0rem;
	background: rgba(118, 147, 121, 0.9);
	padding: 2rem;


	& > div.btn {
		width: 3rem;
		height: 2.5rem;

		& svg {
			width: 100%;
			height: 100%;
			stroke: white;
			&:hover {
				stroke: #b9c43a;
			}
		}
	}
	& > div.cont {
		display: block;
		& ul {
			list-style-type: none;
			margin: 0;
			padding: 0;
			display: flex;
		}
		& li {
padding: 0.2rem 1.4rem;
margin: 0.2rem;
background-color: #889128;
border: 1px solid white;
color: white;
text-align: center;
border-radius: 7px;
display: flex;
justify-content: center;
align-items: center;

		} 
		& li a {
			color: white;

		}
		& li:hover {
			color: black;
			background-color: var(--c12);

		}
	}
}

#feHeader {
	height: $headerOffset;
	background: var(--c11);
	// background-image: url('assets/img/bg1.jpg');
	// position: fixed;
	// width: 100vw;

	top: 0;
	& .btn {
		position: absolute;
		height: 2rem;
		width: 2rem;

		top: 15px;
		right: 26px;

		@media (min-width: $bp1){ 
			display: none;
		}

		& svg.mobilBtn {
			width: 100%;
			height: 100%;
			stroke: white;
			&:hover {
				stroke: #b9c43a;
			}
		}
	}
	& .cont {
		display: flex;

		& .logo {
			position: relative;
			top: 1.3rem;
			left: 0rem;
			z-index: 10;

			@media (max-width: 600px) { 

scale: 0.7;
top: -0.2rem;
			}
		}
		& .innerCont {
			width: 100%;
			display: flex;
			flex-direction: column;
			align-items: stretch;
			padding: .3rem;
			& .headline {
				display: flex;
				flex-wrap: wrap;
				align-items: baseline;
				// line-height: 1.4rem;
				// bottom: -3px;
				// right: 1.2rem;
				
				& span {
					// background-color: #EEE472;

				}

				& span:nth-child(1) {
					font-size: 2.4rem;
					font-weight: bold;
					margin-right: .6rem;
					line-height: 3.2rem;
				color: #d2d983;
					text-shadow: 1px 1px 0px #605e5e;
				}
				& span:nth-child(2) {
					font-size: 1.4rem;
					line-height: .7rem;
					color: white;
					flex-basis: 55%;

				}

			}



		}
	} // end of cont
} // feHeader end



/* --------------  LINKS -------------- */
/* --------------  generell -------------- */
.links {
			display: flex;
	z-index: 20;
	color: white;
	& a {
		text-decoration: none;
		color: white;
		cursor: pointer;

	}
	& ul {

		list-style: none;
		margin: 0;
		padding: 0;
		& li a {
			&:hover {
				background-color: var(--c12);
			}

			&.linkActive {
				border: 1px solid #acb3ac;
				// font-weight: bold;
				background-color: #fff;
				color: #555;
				
			}
		}
	}
	& .subNav {
		
	}
}

/* --------------  mobil -------------- */
@media	(max-width: $bp1){
	.links {
		background-color: var(--c11);
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;

		display: none;
		& ul {
			& li {
				// padding: 0.3rem 2rem;
				line-height: 2.2rem;
				margin:0;


				& a {
					display: block;
					padding-left: 2rem;

					&.linkActive {
						background-color: var(--c12);
						border: none;
						color: white;
					}
				}
			}
		}
		& .subNav li {
			border-top: 1px solid #ffefef;
			margin-left: 2rem;

		}
	}
}
/* --------------  desktop -------------- */
@media	(min-width: $bp1){
	.links {
					display: flex;
		& ul {
			display: flex;
			align-items: baseline;
			& li a {
				background-color: #587d5c;
				padding: 0 1rem;
				margin: 0 .2rem;
				border-radius: 4px;
			}
		}
	}

	.links .subNav {
		& ul {
			position: absolute;
			top: 20px;
			width: 19rem;
			display: none;
			// flex-direction: column;
			// background-color: var(--c11);
			background-color: trasparent;
			padding-top: .1rem;
			// z-index: -1;
			// outline: 1px solid red;
			
			& li { 
				padding: .5rem;
				background-color: var(--c11);
				& a {
					display: block;
				}
				&:hover {
					color: whtite;


				}
			}
		}

	}

}
/* --------------  LINKS end ---------- */



/* --------------  Links Footer ---------- */
/* --------------  generell -------------- */

#footer {

	position: relative;
	& div.cr {
		position: absolute;
		top: 1.5rem;
		right: 0.9rem;
		font-size: small;
		color: #CFCFCF;
		background: var(--c13);
		padding: 0 1rem;
		border-radius: 12px;

		display: none;

				@media (min-width: $bp1){ 
			display: block;
		}

	}
	& ul {
		margin: 0 auto;
		padding: 0;
		list-style: none;
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		align-items: center;
		position: relative;
		top: .8rem;
		max-width: 23rem;
		& li {
			align-items: center;
			line-height: 1.3rem;

			& a {
				text-decoration: none;
				color: white;
				padding: 0 1rem;


				&.linkActive {
					font-weight: bold;
				}
			}
				& a:hover {
					color: var(--c31);
				}


		}
	}
}



/* --------------  mobil -------------- */

/* --------------  desktop -------------- */



/* --------------  Links Footer end ---------- */


.container {
	// height: 100%;
	// overflow-y: auto;
	
}
.row{
	display: flex;
	justify-content: space-around;
	margin-bottom: 2rem;

	@media (max-width: $bp2) {
		display: block;
	}
}


.column {
	min-height: 4rem;
	flex: 1 0 30%;
	box-sizing: border-box;
	padding: 1rem;
}

.col2 {
		flex: 2 0 30%;
}


.img1 {
	width: 100%;
	max-width: 600px;
	margin: auto;
	display: block;
	// border: 1px solid #ddd;
	box-shadow: 3px 3px 3px #b9b9b9;
}


.imgInlineLeft {
	float: left;
	margin: 0 1rem .5rem 0;
	box-shadow: 2px 2px 3px black;
}

.imgInlineRight {
	float: right;
	margin: 0 0 .5rem 1rem ;
	box-shadow: 1px 1px 1px black;
}

h1,h2,h3,h4,h5 {
	font-family: f2;
	margin: 0;
	padding: 0;
	color: #575757;
}

h2 {
	font-size: 1.6rem;
}
h3 {
	font-size: 1.3rem;
}
h4 {

	font-size: 1.1rem;
}

p {
	margin: 0;
	padding: 0;
}

	.f2 {
		font-family: f2;
		font-size: 1rem;
		word-spacing: 0;
		color: #475144;
	}
.smaler {
	font-size: smaller;
}

.cols2_2-8 {
	display: flex;
	justify-content: space-evenly;
	flex-wrap: wrap-reverse;

	& > div {
		padding: 1rem;
	}

	& div:first-child {
		flex: 1;
	}
	& div:nth-child(2) {
		flex: 3;
	}
	@media (max-width: $bp1){ 
		& div {
			flex: 0 0 100% !important;
		}
	}
}

	// @media (min-width: $bp1){ 

	// 		& div:first-child {
	// 			flex: 0 0 100%;
	// 		}
	// 		& div:nth-child(2) {
	// 			flex: 0 0 100%;
	// 		}
	// 	}

	// }


	.cols3_25-50-25 {
		display: flex;
		justify-content: space-evenly;
		flex-wrap: wrap-reverse;

		& > div {
			padding: 1rem;
		box-sizing: border-box;
		}

		& div:first-child, div:nth-child(3) {
			flex: 1 1 30%;
		}
		& div:nth-child(2) {
			flex: 1 1 40%;
		}		
		// & div:nth-child(3) {
		// 	flex: 1 1 25%;
		// }
	}


	@media (max-width: $bp1){ 
		.cols3_25-50-25 {
			// display: flex;
			// justify-content: space-evenly;
			// flex-wrap: wrap-reverse;

			& > div {
				padding: 1rem;
			}

			& div:first-child {
				flex: 1 1 100%;
			}
			& div:nth-child(2) {
				flex: 1 1 100%;
			}		
			& div:nth-child(3) {
				flex: 1 1 100%;
			}
		}
	}



	.hl1 {
		margin-bottom: 1rem;
	}

	.cap {
		margin-bottom: 1rem;
		min-height: 1rem;
	}

	.strong {
		font-weight: bold;
	}

	.hgl1 {
		font-family: f2;
		font-weight: bold;
		text-shadow: 1px 1px 1px #eee;
		color: #5e7560;
	}

	.hgl2 {
		// font-family: f2;
		font-weight: bold;
		color: #5b605b;
	}

	.bor1 {
	padding: 0.7rem;
border: 2px solid #ddd;
border-radius: 6px;
}

	.icon1 {
		display: inline-block;
		position: relative;
		width: .5rem;
		fill: var(--c12);

		& svg {
			position: relative;
			width: .9rem;
			height: .9rem;
			// left: -1.3rem;
			// top: 0.1rem;
		}
	}

	.viriV {
		display: inline-block;
		position: relative;
		width: 0;
		fill: var(--c12);
		top: 0;
		left: -0.6rem;
		& svg {
position: absolute;
width: 1.4rem;
height: 3.2rem;
		}
	}



</style>
