import store from './store'

export function doValidate(l) {
				// par 0: Field Title
				// par 1: Val
				// par 2: rule

				//	DatenKlassen 
				// notNull
				// dezimal
				// datum
				// int
				// intMinMax
				// float
				// email


				const errMsg = [];


				for (let i = 0; i < l.length; i++) {

					let title = l[i][0];
					let val = l[i][1];
					let rule = l[i][2];
					switch(rule) {
						case 'notNull':

						if (val === '' || val === null ) {
							errMsg.push(`Das Feld "${title}" darf nicht leer sein!`);
						}
						break;
						case 'dezimal':

						if (! val.match( /^\d*\,?\d*$/ ) ) {
							errMsg.push(`Das Feld "${title}" muss eine Ganzzahl oder Dezimalzahl mit Komma als Trennzeichen sein`);
						}
						break;
						case 'datum':

						if (! val.match( /^(\d{1,2}\.\d{1,2}\.\d{2,4})?$/ ) ) {
							errMsg.push(`Das Feld "${title}" `);
						}
						break;

						case 'int':
						if (! val.match( /^\d+$/ ) ) {
							errMsg.push(`Das Feld "${title}" muss eine Ganzzahl sein`);
						}
						break;

						case 'intMinMax':
						let ok = false;
						let min = l[i][3];
						let max = l[i][4];

						if ( val.match( /^(\d+)$/ )) {
							ok = true;
							if (val > max) {
								ok = false;
							}
							if (val < min) {
								ok = false;
							}
						}

						if (ok === false) {
						errMsg.push(`Das Feld "${title}" muss eine Ganzzahl zwischne ${min} und ${max} sein!`);
						}
						break;
						
						case 'float':
						if (! val.match( /^(\d+\,?\d*)$/ ) ) {
							errMsg.push(`Das Feld "${title}" muss eine Dezimalzahl(,) oder 0 sein! `);
						}
						break;

						case 'email':
						if ( ! val || ! val.match( /[^\s@]+@[^\s@]+\.[^\s@]+$/ )) {
							errMsg.push(`Im Feld "${title}" muss eine gültige E-Mail Adresse eingetragen sein!`);
						}
						break;
					}


				}
				return errMsg.join("<br />");
			}


export function isAdmin(){

	if(document.cookie){
		if(document.cookie.match(new RegExp(/-viridarium=a12342ddfll/))){
			return true;
		} 
	}

	return false;
}


export function inputToFloat(p) {
	if (p === undefined) {
		return 0;
	}
	let op;
	p = p.toString();
  			// german
  			if (p.match(/\..+,/)) {
  				op = p.replace('.', '');
  				op = op.replace(',', '.');
  			// not german
  		} else if(p.match(/,.+\./)){
  			op = p.replace(',', '');

  		} else {
  			op = p.replace(',', '.');
  		}

  		op = op > 0 ? op : 0;
  		return op;
  	}


  	export function decimalToGerman(p) {
  		if (p === null || p === '') {
  			return 0;
  		}
  		let op;
  		p = p.toString();
  		op = p.replace('.', ',');
  		return op;
  	}
