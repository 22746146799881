<template>
	<div class="container">
		<lightBox ref="lightBox" :actIndex=actIndex :images=images />



		<div class="welcomeBox">
			<div>
				<img v-if=" imgs && imgs.length > 0" class="imgTeam" :title="imgs[0].title" width="314" height="auto" :src="$store.state.server + '/' + imgs[0].path" :alt="imgs[0].alt_text" @click="showLighBoxSingle">
			</div>

			<div>
				<div id="wcBoxHl" class="hl" v-if="html && html.welcomeHead" v-html="html.welcomeHead.html_content"></div>
				<div id="wcBoxCont"  v-if="html && html.welcomeContent" v-html="html.welcomeContent.html_content"></div>
			</div>

		</div>


<fieldset class="infoBox" v-if="showInfoBox()">
	<legend>info</legend>
<!-- 	<div class="icon"><svg class="mobilBtn"><use xlink:href="/img/icons.svg#info"/></svg></div> -->

	<div class="cont">
		<!-- <div class="oefz"> -->
		<!-- </div> -->
		
			<div class="item" v-if="showHideContent('infoBox1')" v-html="html.infoBox1.html_content"></div>
			<div class="item" v-if="showHideContent('infoBox2')" v-html="html.infoBox2.html_content"></div>
			<div class="item" v-if="showHideContent('infoBox3')" v-html="html.infoBox3.html_content"></div>
			<div class="item" v-if="showHideContent('infoBox4')" v-html="html.infoBox4.html_content"></div>
			<div class="item" v-if="showHideContent('infoBox5')" v-html="html.infoBox5.html_content"></div>

	</div>
</fieldset>

<div class="cap"></div>




<div class="wcCont">
	<div class="wcBox1" v-if="showHideContent('wcBox1')">
		<div v-html="html.wcBox1.html_content"></div>
	</div>

	<div class="wcBox2" v-if="showHideContent('wcBox2')">
		<div v-html="html.wcBox2.html_content"></div>
	</div>

	<div class="wcBox3" v-if="showHideContent('wcBox3')">
		<div v-html="html.wcBox3.html_content"></div>
	</div>
</div>

<div class="cap"></div>






<div class="kontaktBox">

	<div class="kontakt">
		<div class="hl">Kontakt:</div>

<div v-if="showHideContent('kontakt')" v-html="html.kontakt.html_content"></div>


		
	</div>
	<div class="standort">
		<div class="hl">Standort:</div>
		<div v-if="showHideContent('standort')" v-html="html.standort.html_content"></div>
	
		<div>
			
			<a class="gm" href="https://www.google.at/maps/place/Viridarium+Gartengestaltung,+Fast+%26+Pauritsch+OG/@46.9315041,15.4800124,17z/data=!3m1!4b1!4m5!3m4!1s0x476fb14c7ecac663:0xb1f4346f78ef7523!8m2!3d46.9315041!4d15.4822064" target="_blank" title="zeigt den Standort auf Google Maps in einer neuen Seite an">
				<img src="/sys_img/googleMapsIcon.png">
				<div>Standort auf Google Maps anzeigen</div>
			</a>

		</div>
	</div>
</div>





	</div>
</template>

<script>
	// import  dialogComp from "@/components/DialogComp.vue";
	import axios from 'axios'
	import * as tools from '@/tools.js'; 
	import  lightBox from "@/components/LightBox.vue";

	export default {
		name: 'Home',
		components: {
			// dialogComp,
			lightBox
		},
		mounted(){
					// this.showHideContent('infoBox1');
			this.getContent();

			
			

		}, // end mounted 
		data: 
		function(){
			return {
				images: [],
				actIndex: null,
				imgs: null,
				html: null,
			}
		}, // end data
		methods: {
			showInfoBox(){
				for (let v in this.html) {
					
					let r = v.match(/infoBox.+/);
					if(this.html[r]){
						if(this.html[r].html_content){
							return true;
						}
					}
				}

				return false;
			},

			showHideContent(p){
				if(this.html){
					if(this.html[p]){
						if(this.html[p].html_content){
							return true;
						}
					}
				}

				return false;
			},
			getContent(){

				let self = this;
				let url = this.$store.state.server + '/api/getContent.php';

				axios.post(url, {
					crossDomain: true,
					headers: {
					},
					data: { slug: 'home' },
				})
				.then(function (resp) {

					self.imgs = resp.data.data[0];
					self.html = resp.data.data[1];
					// self.pk = self.pkFilter = resp.data.data[1];
					// self.showHideContent('infoBox1');

				});
			},

			showLighBoxSingle(e){

				let selSrc = e.target.currentSrc;

				this.images = []
				this.images.push({src: selSrc, height: e.target.naturalHeight, width: e.target.naturalWidth, title: e.target.title, alt: e.target.alt});
				this.actIndex = 0;
			}


		} // end methods
	} // end export default

</script>



<style  lang="scss">
	.container {
		height: 100%;
		// background-color: var(--c12);
	}


	.imgTeam {

		border-radius: 50%;
		scale: .85;
		box-shadow: 3px 3px 3px #b9b9b9;
		width: 100%;
	}

	#app .welcomeBox {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-wrap: wrap;

		& div {

		}

		& div:nth-child(1){
			flex: 1 1 15%;

		}
		& div:nth-child(2){
			flex: 1 1 60%;


			& div.hl {

				color: #8e8e8a;
				font-family: f2;
				font-weight: bold;
				line-height:  2rem;
				font-size: 2rem;
				margin-bottom: 1rem;
			}
			& div.hl2{

				color: #51514e;
				font-family: f1;
				font-weight: bold;
				margin-bottom: .1rem;

				font-size: 1.2rem;
			}
		}
	}

	// mobil
@media (max-width: 600px) {
		#app .welcomeBox {
			display: block;

			& .imgTeam {
				width: 47%;
				display: block;
				margin: 0 auto;
			}

			& div.hl { 
			text-align: center;
			}
			& div.hl2 {
				text-align: center;
	padding-bottom: 0.4rem;
			}
		}
	}


	$borW: 2px;
	$borS: dotted;
	.wcCont {
			// border: 1px solid silver;

			display: flex;
			justify-content: space-evenly;

			& div {
				flex: 1 0 30%;
				padding: .4rem;
			}
			& .wcBox1 {
					border-left: $borW $borS var(--c31);

			}
						& .wcBox2 {
					border-left: $borW $borS var(--c14);
				
			}
						& .wcBox3 {		
					border-left: $borW $borS var(--c22);

			}
	}

	@media (max-width: 600px) { 
		.wcCont {
			flex-direction: column;

			& div {
				border: none !important;
			}
		}
	}



	.infoBox {
		display: flex;
		position: relative;
		border: 2px solid var(--c31);
		border-radius: 6px;
		
		& legend {
			padding: 0 0.6rem;
			color: var(--c31);
			font-weight: bold;
			letter-spacing: 0px;
			font-size: 1.5rem;
			font-family: f2;
		}

		& .icon {
			position: absolute;
			bottom: 10.7rem;
			right: -1.6rem;
			fill: var(--c31);
			stroke: var(--c31);
			background-color: white;
			& svg {
				width: 3rem;
				height: 3rem;
				display: none;
			}
		}
		& .cont {
			// padding: 1rem;
		}
	}

	.infoBox .cont {
		width: 100%;
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		align-items: center;

		& > div.item {
			flex: 0 0 42%;
		// border: 1px solid silver;
		padding: 1rem;
		margin: 1rem;
		border-radius: 4px;
			text-align: left;
		}

	// 	& div.oefz {
	// 	text-align: center;
	// 	// border: 1px solid silver;
	// 	padding: 1rem;
	// 	margin: 1rem;
	// 	border-radius: 4px;
	// }

	}

	@media (max-width: 600px) { 
		.infoBox { 
			margin-top: 1rem;
			margin-bottom: -1rem;

			& .cont > div.item {

				padding: .4rem;
		margin: .4rem;
		flex: 1 0 100%;
				
			} 

		}
	}



	.kontaktBox {
		display: flex;
		position: relative;
		border: 1px solid silver;
		border-radius: 4px;
		text-align: left;
		padding: .6rem;

		& a {
			text-decoration: none;
			color: #389da2;
		}

		& > div {
			flex: 1 1 40%;
			margin: 0 5px;

		}
		& div.kontakt {
			& .hl {
				background: var(--c12);
				padding: .2rem 1rem;
				color: white;
				font-family: f2;
				font-size: 1.2rem;
				margin-bottom: .3rem;	

			}

		}


		& div.standort {
			& .hl {
				background: var(--c12);
				padding: .2rem 1rem;
				color: white;
				font-family: f2;
				font-size: 1.2rem;
				margin-bottom: .3rem;	
			}
		}

		& .gm {
			display: flex;
			align-items: center;

			& img {
				margin-right: .4rem;
				margin-bottom: 3px;
				scale: .7;
			}
		}
	}

		@media (max-width: 600px) { 

			.kontaktBox {
				flex-direction: column;
				margin-bottom: 1rem;

			}

		}

</style>
