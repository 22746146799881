import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import * as tools from '../tools.js'; 

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/gartengestaltung',
    name: 'gartengestaltung',
    component: () => import('../views/GartengestaltungParent.vue'),
    children: [
        {
        	name: 'gg-gartengestaltung',
          path: 'main',
          component: () => import('../views/GG_Gartengestaltung.vue'),
        },
        {
        	name: 'gg-planung',
          path: 'beratung',
          component: () => import('../views/GG_Beratung_Planung.vue'),
        },
        {
        	name: 'gg-mauern',
        	path: 'mauern-wege',
        	component: () => import('../views/GG_Mauern_Wege.vue'),
        },
                {
        	name: 'gg-teiche',
        	path: 'teiche',
        	component: () => import('../views/GG_Teiche.vue'),
        },
                {
        	name: 'gg-bepflanzung',
        	path: 'bepflanzung',
        	component: () => import('../views/GG_Bepflanzung.vue'),
        },
                {
        	name: 'gg-teichpflege',
        	path: 'teichpflege',
        	component: () => import('../views/GG_Teichpflege.vue'),
        }
      ]

  },
    {
    path: '/kontakt',
    name: 'Kontakt',
    component: () => import('../views/Kontakt.vue')
  },
    {
    path: '/schaugarten',
    name: 'Schaugarten',
    component: () => import('../views/Schaugarten.vue')
  },
    {
    path: '/unsere-arbeiten',
    name: 'UnserArbeiten',
    component: () => import('../views/UnsereArbeiten.vue')
  },
    {
    path: '/pflanzenkatalog',
    name: 'Pflanzenkatalog',
    component: () => import('../views/Pflanzenkatalog.vue')
  },
    {
    path: '/impressum',
    name: 'Impressum',
    component: () => import('../views/Impressum.vue')
  },
    {
    path: '/links',
    name: 'Links',
    component: () => import('../views/Links.vue')
  },
    {
    path: '/agb',
    name: 'AGB',
    component: () => import('../views/AGB.vue')
  },
    {
    path: '/datenschutz',
    name: 'Datenschutz',
    component: () => import('../views/Datenschutz.vue')
  },
    {
    path: '/informationspflicht',
    name: 'Informationspflicht',
    component: () => import('../views/Informationspflicht.vue')
  },
  {
    path: '*',
    name: 'logIn',
    component: () => import('../views/LogIn.vue')
  }
];

if(tools.isAdmin()){

// for BackEnd
routes.push( {
	path: '/pflanzenkatalogList',
	name: 'PflanzenkatalogList',
	component: () => import('../be/PflanzenkatalogList.vue')
},
{
	path: '/imgEditList',
	name: 'ImgEditList',
	component: () => import('../be/ImgEditList.vue')
},
{
	path: '/HomeEditList',
	name: 'homeEditList',
	component: () => import('../be/HomeEditList.vue')
},
{
	path: '/schaugartenList',
	name: 'SchaugartenList',
	component: () => import('../be/SchaugartenList.vue')
},
{
	path: '/unsereArbeitenList',
	name: 'UnsereArbeitenList',
	component: () => import('../be/UnsereArbeitenList.vue')
},
{
	path: '/infos',
	name: 'Infos',
	component: () => import('../be/Infos.vue')
}

)
}


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})


export default router
