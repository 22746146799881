import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  	users: [{username:'mez', pw: 'trepur'}, {username:'50217006', pw: 'h858c94'}],
  	server: 'https://www.viridarium.at',
  	// server: 'http://viri',
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
